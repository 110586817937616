import { createTheme } from "@material-ui/core/styles";

export const LightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ff7f1e",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#00599C",
    },
  },
});
export const DarkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#ef6c00",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#2ab7f7",
    },
  },
});

export default LightTheme;
