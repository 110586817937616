import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { Loading } from "@comsel/util-components";

const AssetInventory = React.lazy(() => import("./components/Inventory/AssetInventory"));
const MapView = React.lazy(() => import("./components/Map/Map"));
const NewEntity = React.lazy(() => import("./components/New/New"));
const Asset = React.lazy(() => import("./components/Asset/Asset"));
const Data = React.lazy(() => import("./components/Asset/Data"));
const Device = React.lazy(() => import("./components/Asset/Device"));
const Status = React.lazy(() => import("./components/Asset/Status"));
const Quality = React.lazy(() => import("./components/Asset/Quality"));
const Workorder = React.lazy(() => import("./components/Asset/Workorder"));
const Alarm = React.lazy(() => import("./components/Asset/Alarm"));
const NewConfiguration = React.lazy(() => import("./components/Configuration/New"));
const Configuration = React.lazy(() => import("./components/Configuration/Configuration"));
const PortResultTable = React.lazy(() => import("./components/Asset/PortResultTable"));
const DataManagement = React.lazy(() => import("./components/DataManagement/DataManagement"));
const ReadingProfile = React.lazy(() => import("./components/ReadingProfiles/ReadingProfile"));
const FactoryView = React.lazy(() => import("./components/Asset/FactoryView"));
const AddAssembly = React.lazy(() => import("./components/New/AddAssembly"));
const User = React.lazy(() => import("./components/User/User"));
const Docs = React.lazy(() => import("./components/Docs"));

let routes = (props: any) => (
  <React.Fragment>
    <Suspense fallback={<Loading />}>
      <Route exact path="/" component={AssetInventory} />
      <Route exact path="/assets" component={AssetInventory} />
      <Route exact path="/map" component={MapView} />
      <Route exact path="/map/:uuid" component={MapView} />
      <Route exact path="/data" component={DataManagement} />
      <Route exact path="/new" component={NewEntity} />
      <Route path="/assets/:deviceType" component={AssetInventory} />
      <Route path="/asset/:uuid" component={Asset} />
      <Route path="/asset/:uuid/data" component={Data} />
      <Route path="/asset/:uuid/device" component={Device} />
      <Route path="/asset/:uuid/status" component={Status} />
      <Route path="/asset/:uuid/quality" component={Quality} />
      <Route path="/asset/:uuid/wo/:wo" component={Workorder} />
      <Route path="/asset/:uuid/alarm/:id" component={Alarm} />
      <Route path="/asset/:uuid/port/:id" component={PortResultTable} />
      <Route path="/asset/:uuid/factory" component={FactoryView} />
      <Route path="/asset/:uuid/assembly" component={AddAssembly} />
      <Route path="/configuration/new" exact component={NewConfiguration} />
      <Route path="/configuration/:id" exact component={Configuration} />
      <Route path="/reading-profiles/:id" exact component={ReadingProfile} />
      <Route path="/user" exact component={User} />
      <Route path="/docs" exact component={Docs} />
    </Suspense>
  </React.Fragment>
);

export default routes;
