import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useLocation } from "react-router";
import { createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createTheme";
import Paper from "@material-ui/core/Paper";

import Routes from "./routes";

import MainAppBar from "./components/Layout/MainAppBar";
import MainTabBar from "./components/Layout/MainTabBar";
import MainSideDrawer from "./components/Layout/MainSideDrawer";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

const drawerWidth = 320;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
    contentWrapper: {
      flexGrow: 1,
      overflow: "auto",
      width: "100%",
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    contentWrapperLeftDrawer: {
      flexGrow: 1,
      overflow: "auto",
      width: "calc(100% - " + drawerWidth + "px)",
      marginLeft: drawerWidth,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    content: {
      margin: theme.spacing(3, 2),
    },
    contentRightSidebar: {
      margin: theme.spacing(3, 2),
      marginRight: theme.spacing(9),
    },
    toolbar: theme.mixins.toolbar,
  })
);

export const App = () => {
  const theme = useTheme();
  const width = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  let [showDrawer, setShowDrawer] = useState(width);
  const [showRightDrawer, setShowRightDrawer] = useState(false);
  const location = useLocation();
  const [lockedOpen, setLockedOpen] = useState(false);

  useEffect(() => {
    if (width && !showDrawer && !lockedOpen) {
      const showDrawer = !location.pathname.startsWith("/docs");
      setShowDrawer(showDrawer);
    }
  }, [width, lockedOpen, showDrawer, location.pathname]);

  useEffect(() => {
    setShowRightDrawer(location.pathname.startsWith("/asset/"));
  }, [location.pathname]);

  const toggleDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowDrawer(!showDrawer);
    setLockedOpen(true);
  };
  return (
    <div className={classes.root}>
      <MainAppBar onMenuClick={toggleDrawer} />
      <div className={classes.toolbar} />
      <MainTabBar />
      <MainSideDrawer hidden={!showDrawer} />
      <div
        className={clsx({
          [classes.contentWrapperLeftDrawer]: showDrawer,
          [classes.contentWrapper]: !showDrawer,
        })}
      >
        <Paper className={showRightDrawer ? classes.contentRightSidebar : classes.content}>
          <Routes />
        </Paper>
      </div>
    </div>
  );
};

export default App;
