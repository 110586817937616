import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import sv from "./lang/sv.json";
import en from "./lang/en.json";
import fi from "./lang/fi.json";
// the translations
const resources = {
  sv: sv,
  fi: fi,
  en: en,
};

const findBestLocale = () => {
  if (localStorage["locale"] !== undefined) {
    for (let supportedLocale in resources) {
      if (localStorage["locale"] === supportedLocale) {
        return localStorage["locale"];
      }
    }
  } else {
    return "en";
  }
};
let language = findBestLocale();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    debug: true,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
