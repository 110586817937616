import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

/*
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';*/
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabBar: {
      width: "100%",
      zIndex: 99,
    },
  })
);

export const MainTabBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [t] = useTranslation();
  const handleCallToRouter = (event: React.ChangeEvent<unknown>, value: string) => {
    history.push(value);
  };
  let value: string | boolean = "/" + location.pathname.split("/")[1];
  if (value.length < 2) {
    value = "/assets";
  }
  if (!(value === "/status" || value === "/assets" || value === "/bi" || value === "/map" || value === "/events" || value === "/data")) {
    value = false;
  }
  return (
    <div className={classes.tabBar}>
      <Paper elevation={1} square>
        <Tabs centered value={value} onChange={handleCallToRouter} indicatorColor="primary" textColor="primary" scrollButtons="auto">
          <Hidden xsDown>
            <Tab value="/status" label={t("Layoutfolder.slaMonitor")} disabled></Tab>
          </Hidden>
          <Tab value="/assets" label={t("Layoutfolder.assetInventory")}></Tab>
          <Hidden xsDown>
            <Tab value="/bi" label={t("Layoutfolder.businessIntelligence")} disabled></Tab>
          </Hidden>
          <Tab value="/map" label={t("Layoutfolder.map")}></Tab>
          <Hidden xsDown>
            <Tab value="/events" label={t("Layoutfolder.eventManagement")} disabled></Tab>
            <Tab value="/data" label={t("Layoutfolder.dataManagement")}></Tab>
          </Hidden>
        </Tabs>
      </Paper>
    </div>
  );
};

export default MainTabBar;
