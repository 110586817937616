import React, { useState, useEffect, useContext } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { promptLogin, clear, getCurrentUser } from "@comsel/corona";
import { NewNode } from "@comsel/corona";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import LanguageMenu from "./LanguageMenu";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { GraphContext } from "../../context/GraphContext";
import { CredentialsContext, CredentialsContextDefault } from "../../context/CredentialContext";

export const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const [languageOpen, setLanguageOpen] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [, setGraph] = useContext(GraphContext);
  const [cred, setCred] = useContext(CredentialsContext);
  const theme = useTheme();
  const [t] = useTranslation();
  const history = useHistory();

  useEffect(
    () => {
      if (user.id.isZero()) {
        getCurrentUser(cred).then(u => { setUser(u);  });
      }
    },
    // eslint-disable-next-line
    [user]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReload = () => {
    handleClose();
    clear().then(() => window.location.reload(true));
  };
  const closeLanguageMenu = () => {
    setLanguageOpen(false);
  };
  const openLanguageMenu = (o: React.SetStateAction<boolean>) => {
    setLanguageOpen(o);
  };
  const handleChangeColorMode = () => {
    handleClose();
    const useDark = localStorage["dark"] == null ? theme.palette.type === "dark" : localStorage["dark"] === "true";
    if (useDark) {
      localStorage["dark"] = "false";
    } else {
      localStorage["dark"] = "true";
    }
    window.location.reload();
  };
  const handleLogout = () => {
    setCred(CredentialsContextDefault);
    handleClose();
    clear();
    setGraph(NewNode());
    setTimeout(() => {
      if (process.env.NODE_ENV === "development") {
        promptLogin();
      } else {
        if (process.env.CLIENT_ID != null) {
          promptLogin(process.env.CLIENT_ID);
        } else {
          promptLogin("40ada7e2-afa2-4b84-aabe-146b24f76460");
        }
      }
    }, 1000);
  };
  const useDark = localStorage["dark"] == null ? theme.palette.type === "dark" : localStorage["dark"] === "true";

  return (
    <div>
      <IconButton color="inherit" aria-controls="user-menu" aria-haspopup="true" aria-label="User menu" onClick={handleClick}>
        <AccountCircleIcon />
      </IconButton>
      <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleChangeColorMode}>
          {useDark ? "Light" : "Dark"} {t("Layoutfolder.mode")}
        </MenuItem>
        <MenuItem onMouseEnter={closeLanguageMenu} onClick={handleReload}>
          {t("Layoutfolder.reload")}
        </MenuItem>
        <LanguageMenu open={languageOpen} openChange={openLanguageMenu}></LanguageMenu>
        <MenuItem
          onMouseEnter={closeLanguageMenu}
          onClick={() => {
            history.push("/user");
            handleClose();
          }}
        >
          {t("Layoutfolder.user")}
        </MenuItem>
        <MenuItem
          onMouseEnter={closeLanguageMenu}
          onClick={() => {
            history.push("/docs");
            handleClose();
          }}
        > {t("Layoutfolder.docs")}</MenuItem>
        <MenuItem onMouseEnter={closeLanguageMenu} onClick={handleLogout}>
          {t("Layoutfolder.logout")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
