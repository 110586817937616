import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import ReactDOM  from "react-dom";
import { BrowserRouter } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";

import { QueryClient, QueryClientProvider } from '@comsel/query'; // needs to be from here to ensure we match version

import App from "./App";
import { DarkTheme, LightTheme } from "./theme";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { promptLogin, clear, UserMetadata, decodeCredentials, Credentials, loginURL } from "@comsel/corona";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./i18n/i18n";
import { CredentialsContext, CredentialsContextDefault, CredentialsContextInterface } from "./context/CredentialContext";
import { ContextControl } from "./ContextControl";
import ComselIconDark from "./components/icons/ComselIconDark";

/*
import { PersistedClient, persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import { decodeNode } from "@comsel/corona/dist/graph";
import { decodePorts } from "@comsel/corona/dist/ports";
*/


// Create a client
const queryClient = new QueryClient({defaultOptions: {
  queries: {
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
  }}
});

/* performance murder
const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
  deserialize: (s) => {
    const cacheJSON: PersistedClient = JSON.parse(s);
    for (let i=0;i<cacheJSON.clientState.queries.length;i++) {
      if (cacheJSON.clientState.queries[i].queryKey[0]==="graph" && cacheJSON.clientState.queries[i].state.data != null) {
        cacheJSON.clientState.queries[i].state.data = decodeNode(cacheJSON.clientState.queries[i].state.data);
      }
      if (cacheJSON.clientState.queries[i].queryKey[0]==="ports" && cacheJSON.clientState.queries[i].state.data != null) {
        cacheJSON.clientState.queries[i].state.data = decodePorts(cacheJSON.clientState.queries[i].state.data);
      }
      if (cacheJSON.clientState.queries[i].queryKey[0]==="alarms" && cacheJSON.clientState.queries[i].state.data != null) {
        //cacheJSON.clientState.queries[i].queryKey[0]
      }
      if (cacheJSON.clientState.queries[i].queryKey[0]==="units" && cacheJSON.clientState.queries[i].state.data != null) {
        if ((cacheJSON.clientState.queries[i].state.data as Array<any>).length!=null) {
          for (let j=0;j<(cacheJSON.clientState.queries[i].state.data as Array<any>).length;j++) {
            (cacheJSON.clientState.queries[i].state.data as Array<any>)[j] = decodeUnit((cacheJSON.clientState.queries[i].state.data as Array<any>)[j]);
          }
        }
      }
    }
    console.log("Decoded from localstorage: ", cacheJSON.clientState.queries);
    return cacheJSON;
  },
})

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});
*/



const loadCredentialsFromStorage = () => { return (new Credentials()).load(); }

function Root() {
  const [credentials, setCredentials] = useState<Credentials>(CredentialsContextDefault);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const useDark = localStorage == null || localStorage["dark"] == null ? prefersDarkMode : localStorage["dark"] === "true";
  const [hasSynthesizedCredentials, setHasSynthesizedCredentials] = useState(false);

  let loginUrl = loginURL();
  if (process.env.NODE_ENV !== "development") {
    if (process.env.CLIENT_ID != null) {
      loginUrl = loginURL(process.env.CLIENT_ID);
    } else {
      loginUrl = loginURL("40ada7e2-afa2-4b84-aabe-146b24f76460");
    }
  }


  useEffect(() => {
    if (window.location.hash.length > 10) {
      try {
        let c = decodeCredentials(window.location.hash);
        console.log("Had credentials in location.hash during startup")
        c.store().then(() => {
          setCredentials(c);
          window.location.hash = "";
          setHasSynthesizedCredentials(true);
        })
      } catch (e) {
        console.log("Had something in location.hash during startup but probably wasn't auth parameters: ", window.location.hash.length, " credentials decoding returned: ", e)
        loadCredentialsFromStorage().then((c) => {
          setCredentials(c);
          setHasSynthesizedCredentials(true);
        }).catch(e => {
          setHasSynthesizedCredentials(true);
        });
        // try to load
      }
    } else {
        loadCredentialsFromStorage().then((c) => {
          setCredentials(c);
          setHasSynthesizedCredentials(true);
        }).catch(e => {
          setHasSynthesizedCredentials(true);
        });
    }
  }, []);

  useEffect(() => {
    if (credentials.token().length>0) {
      if (credentials.valid()) {
        let u = new UserMetadata("comsel.ServiceHub", "locale");
        u.get(credentials).then((ret) => {
          if (ret != null && ret.value != null && ret.value && (ret.value === "en" || ret.value === "sv" || ret.value === "fi")) {
            localStorage["locale"] = ret.value;
          }
        }).catch(e => console.log("No user metadata for locale found, got:", e));
      } else {
        console.log("auth was not valid :(");
        clear();
        if (process.env.NODE_ENV === "development") {
          promptLogin();
        } else {
          if (process.env.CLIENT_ID != null) {
            promptLogin(process.env.CLIENT_ID);
          } else {
            promptLogin("40ada7e2-afa2-4b84-aabe-146b24f76460");
          }
        }
      }
    } else if (hasSynthesizedCredentials) {
      console.log("Credentials token length is null:", credentials);
      if (process.env.NODE_ENV === "development") {
        promptLogin();
      } else {
        if (process.env.CLIENT_ID != null) {
          promptLogin(process.env.CLIENT_ID);
        } else {
          promptLogin("40ada7e2-afa2-4b84-aabe-146b24f76460");
        }
      }
    }
  }, [credentials, hasSynthesizedCredentials]);


  const handleSetCredentials = (c: CredentialsContextInterface) => { setCredentials(c); }

  const theme = React.useMemo(() => (useDark ? DarkTheme : LightTheme), [useDark]);

  if (!credentials.valid()) {
    return <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh"}} >
      {ComselIconDark}
      <div style={{fontFamily: "sans-serif", paddingTop: "32px"}}>You should be redirected to sign in soon, if not then you can click: <a href={loginUrl}>here</a></div>
    </div>
  }

  return (<QueryClientProvider client={queryClient}>
    <CredentialsContext.Provider value={[credentials, handleSetCredentials]}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <BrowserRouter>
        <ContextControl>
          <App />
        </ContextControl>
        </BrowserRouter>
    </ThemeProvider>
    </CredentialsContext.Provider>
    </QueryClientProvider>);
}

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

