import React, { useState, useEffect, useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createTheme";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { GraphNode, NewNode, Field } from "@comsel/corona";

import { Loading } from "@comsel/util-components";
import GraphList from "./GraphList";
import { useTranslation } from "react-i18next";
import { CredentialsContext } from "../../context/CredentialContext";
import { GraphContext } from "../../context/GraphContext";

const drawerWidth = 320;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: theme.zIndex.appBar - 1,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    loadMoreButton: {
      textAlign: "center",
      color: theme.palette.primary.light,
      fontWeight: theme.typography.button.fontWeight,
      fontSize: theme.typography.button.fontSize,
    },
  })
);

type Props = {
  hidden: boolean;
};
export const MainSideDrawer = (props: Props) => {
  const classes = useStyles();
  const [activeGraph, setActiveGraph] = useState<GraphNode | undefined>();
  const [blocks, setBlocks] = useState(0);
  const [graph] = useContext(GraphContext);
  const [cred] = useContext(CredentialsContext);
  const [t] = useTranslation();

  useEffect(() => {
    // set up initial graph
    const expandable = (node: GraphNode): boolean => {
      return node.deviceType === -1 || node.deviceType === 1 || node.deviceType === 3;
    };
    const processGraph = (node: GraphNode): GraphNode => {
      node.children = node.children.filter((rel) => {
        return !(rel.type === 2 || rel.type === 6) && rel.weight>=0;
      });
      node.children.sort((a, b) => {
        if (expandable(a.node) && !expandable(b.node)) {
          return -1;
        }
        if (expandable(b.node) && !expandable(a.node)) {
          return 1;
        }
        if (a.node.deviceType < b.node.deviceType) {
          return -1;
        }
        if (a.node.deviceType > b.node.deviceType) {
          return 1;
        }
        if (a.node.assetid < b.node.assetid) {
          return -1;
        }
        if (a.node.assetid > b.node.assetid) {
          return 1;
        }
        return 0;
      });
      return node;
    };

    if (cred != null && cred.valid()) {
      NewNode()
        .get([Field.Model], cred)
        .then((val) => {
          if (val != null) {
            if (val.children.length === 1) {
              console.log("Only only has one child, setting it as root");
              val.children[0].node.get([], cred).then((v) => { // if we only got one then we hide that one and pretend its children are the graph
                if (v != null) {
                  setActiveGraph(processGraph(v));
                }
              });
            } else {
              setActiveGraph(processGraph(val));
            }
          }
        });
    } // Warning Message, but no clue why?
  }, [cred]);

  const listOfChildren = (node: GraphNode) => {
    const blockSize = 100;
    const end = blockSize + blocks * blockSize;
    return node.children
      .filter(r => r.weight>=0)
      .slice(0, end)
      .map((rel) => <GraphList key={rel.node.uuid.string()} autoExpand isNested={false} node={rel.node} stripPrefix={node.assetid} />);
  };
  const handleMoreClick = (event: React.MouseEvent<unknown>) => {
    setBlocks(blocks + 1);
  };
  const DrawerContent =
    activeGraph == null ? (
      <Loading label={t("loading")} />
    ) : (
      <List dense>
        {listOfChildren(activeGraph)}
        {graph.children.length > 100 ? (
          <ListItem button onClick={(event) => handleMoreClick(event)}>
            <ListItemText className={classes.loadMoreButton} primary={t("Layoutfolder.loadMore")} />
          </ListItem>
        ) : (
          <React.Fragment />
        )}
      </List>
    );

  return (
    <Drawer variant="persistent" anchor="left" open={!props.hidden} className={classes.drawer} classes={{ paper: classes.drawerPaper }}>
      <div className={classes.toolbar} />
      {DrawerContent}
    </Drawer>
  );
};

export default MainSideDrawer;
