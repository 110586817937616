import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createTheme";
import { useTranslation } from "react-i18next";

import { OmniInput } from "@comsel/util-components";

import { useKeyPress } from "../../hooks/useKeyPress";
import { FilterContext } from "../../context/FilterContext";
import { useUnitsWindowed } from "@comsel/query";
import { CredentialsContext } from "../../context/CredentialContext";
import { Unit } from "@comsel/corona";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchRoot: {
      marginRight: theme.spacing(2),
    },
  })
);
export const Search = () => {
  const classes = useStyles();
  const history = useHistory();
  const [cred] = useContext(CredentialsContext);
  const [filter, setFilter] = useContext(FilterContext);
  const [initialInputLatch, setInitialInputLatch] = useState(filter.length>0);
  const [input, setInput] = useState(filter);
  const units = useUnitsWindowed("assetid", ((filter.length>0) ? 100 : 0), 0, filter, cred);
  const searchFocusRequested = useKeyPress("/");
  const [keyPressed, setKeyPressed] = useState(false);
  const [focus, setFocus] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (searchFocusRequested) {
      setKeyPressed(true);
    } else {
      if (keyPressed && !focus) {
        setFocus(true);
        setKeyPressed(false);
      } else {
        setFocus(false);
      }
    }
  }, [searchFocusRequested, keyPressed, focus]);
  useEffect(() => {
    if (filter.length>0 && input==="" && !initialInputLatch) {
      setInput(filter);
      setInitialInputLatch(true);
    }
  }, [filter, input, initialInputLatch]);
  const handleAutocompleteChange = (value: Unit) => {
    history.push("/asset/" + value.uuid.string() + "/data");
  };

  const changeURL = (value: string) => {
    let rawSearch = history.location.search;
    let searchComponents = [];
    if (rawSearch.length>1) {
      // eslint-disable-next-line array-callback-return
      searchComponents = rawSearch.substr(1).split("&").map((v) => {
        if (v.startsWith("q=")) {
          if (value.length>0) {
            return "q="+value;
          }
        } else {
          return v;
        }
      });
    } else if (value.length>0) {
      searchComponents.push("q="+value);
    }
    history.replace({search: "?"+searchComponents.join("&")});
  }

  const handleInputChange = (value: string) => {
    setInput(value);
    setTimeout(() => {
      setFilter(value);
      changeURL(value);
    }, 0);
  }

  const options: Array<Unit> = [];
  if (units.data != null && units.data.length>0 &&
    filter.length>2) {
    units.data.map(u => options.push(u));
  }
  return (
    <div className={classes.searchRoot}>
      <OmniInput focus={focus} variant="search" placeholder={t("search")} onUnitSelect={handleAutocompleteChange} options={options} onChange={() => { console.log("on change") }} onInputChange={handleInputChange} value={input} loading={input.length>0 && units.isFetching}  />
    </div>
  );
};

export default Search;
