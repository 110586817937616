import React from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
//import { WithStyles, withStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createTheme";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

import MenuIcon from "@material-ui/icons/Menu";

import Search from "./Search";
import BreadCrumbs from "./Breadcrumbs";
import UserMenu from "./UserMenu";
import ComselIcon from "../icons/ComselIcon";
import AlarmButton from "./AlarmButton";

//const styles = (theme: Theme) => createStyles({
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: { flexGrow: 1 },
  })
);

//interface Props extends WithStyles<typeof styles> { onMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void}

interface Props {
  onMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const MainAppBar = (props: Props) => {
  /*const MainAppBar = withStyles(styles)(
  class extends Component<Props> {*/
  // render() {
  //const { classes } = this.props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar position="absolute">
        <Toolbar>
          <IconButton color="inherit" onClick={props.onMenuClick} aria-label="Toggle device tree">
            <MenuIcon />
          </IconButton>
          <Hidden smDown>
            <Button component={Link} to="/" aria-label="Home">
              {ComselIcon}
            </Button>
          </Hidden>
          <div className={classes.grow} />
          <Hidden smDown>
            <BreadCrumbs />
          </Hidden>
          <div className={classes.grow} />
          <Search />
          <AlarmButton />
          <UserMenu />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
/*);*/

export default MainAppBar;
