import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { GraphNode, Field } from "@comsel/corona";

import { DeviceIcon } from "@comsel/util-components";
import { Loading } from "@comsel/util-components";
import { useTranslation } from "react-i18next";
import { CredentialsContext } from "../../context/CredentialContext";
import { GraphContext } from "../../context/GraphContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemActive: {
      color: theme.palette.primary.main,
    },
    listItemText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    listItemIcon: {
      minWidth: "30px",
    },
    typeIconActive: {
      fill: theme.palette.primary.main,
    },
    expandIconHolder: {
      width: "24px",
      display: "inline-block",
      flexShrink: 0,
    },
    nestedList: {
      paddingLeft: theme.spacing(3),
    },
    loadMoreButton: {
      textAlign: "center",
      color: theme.palette.primary.light,
      fontWeight: theme.typography.button.fontWeight,
      fontSize: theme.typography.button.fontSize,
    },
    toolbar: theme.mixins.toolbar,
  })
);

type Props = {
  node: GraphNode;
  isNested: boolean;
  autoExpand: boolean;
  style?: any;
  stripPrefix?: string;
};
/* Takes a node and returns a ListItem  */
export const GraphList = (props: Props) => {
  const classes = useStyles();
  const [activeGraph, setActiveGraph] = useState<GraphNode | undefined>(undefined);
  const [expanded, setExpanded] = useState(false);
  const [userExpand, setUserExpanded] = useState(false);
  const [ancestor, setAncestor] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [blocks, setBlocks] = useState(0);
  const history = useHistory();
  const [cred] = useContext(CredentialsContext);
  const [graph] = useContext(GraphContext);
  const [t] = useTranslation();

  useEffect(() => {
    setActiveGraph(props.node);
  }, [props.node]);

  useEffect(() => {
    if (props.autoExpand) {
      let stateChanged = false;

      if (ancestor === false) {
        if (graph != null && graph.isAncestor(props.node)) {
          setAncestor(true);
          stateChanged = true;
        }
      } else {
        if (graph == null || !graph.isAncestor(props.node)) {
          setAncestor(false);
          stateChanged = true;
        }
      }
      if (!userExpand && graph != null) {
        if (ancestor) {
          if (!expanded) {
            getChildren();
            setExpanded(true);
            stateChanged = true;
          }
        } else if (expanded) {
          setExpanded(false);
          stateChanged = true;
        }
      }
      if (stateChanged) {
        setClicked(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.autoExpand, ancestor, expanded, graph, props.node, userExpand]);

  const getChildren = () => {
    if (cred != null && cred.valid()) {
      props.node.get([Field.Model], cred).then((val) => {
        if (val != null) {
          val.children = val.children.filter((rel) => {
            return !(rel.type === 2 || rel.type === 6) && rel.weight>=0;
          });
          val.children.sort((a, b) => {
            if (expandable(a.node) && !expandable(b.node)) {
              return -1;
            }
            if (expandable(b.node) && !expandable(a.node)) {
              return 1;
            }
            if (a.node.deviceType < b.node.deviceType) {
              return -1;
            }
            if (a.node.deviceType > b.node.deviceType) {
              return 1;
            }
            if (a.node.assetid < b.node.assetid) {
              return -1;
            }
            if (a.node.assetid > b.node.assetid) {
              return 1;
            }
            return 0;
          });
        }
        setActiveGraph(val);
      });
    }
  };
  const toggleExpand = () => {
    if (!expanded) {
      getChildren();
    }
    setExpanded(!expanded);
  };
  const handleListItemClick = (event: React.MouseEvent<unknown>) => {
    console.log("handleListItemClick", activeGraph);
    if (activeGraph != null) {
      history.push("/asset/" + activeGraph.uuid.string() + "/data");
      setClicked(true);
    }
  };
  const handleExpandIconClick = (event: React.MouseEvent<unknown>) => {
    toggleExpand();
    setUserExpanded(true);
  };
  const handleMoreClick = (event: React.MouseEvent<unknown>) => {
    setBlocks(blocks + 1);
  };

  const isActiveNode = (node: GraphNode): boolean => {
    if (graph != null) {
      return node.uuid.equals(graph.uuid);
    }
    return node.uuid.string() === "00000000-0000-0000-0000-000000000000";
  };
  const expandable = (node: GraphNode): boolean => {
    return node.deviceType === -1 || node.deviceType === 1 || node.deviceType === 3;
  };

  let expand = (node: GraphNode) => {
    const blockSize = 100;
    const end = blockSize + blocks * blockSize;
    return (
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding className={classes.nestedList}>
          {node.children.slice(0, end).map((rel) => (
            <GraphList key={rel.node.uuid.string()} autoExpand node={rel.node} isNested stripPrefix={node.assetid} />
          ))}
          {node.children.length > blockSize ? (
            <ListItem button onClick={(event) => handleMoreClick(event)}>
              <ListItemText className={classes.loadMoreButton} primary={t("Layoutfolder.loadMore")} />
            </ListItem>
          ) : (
            <React.Fragment />
          )}
        </List>
      </Collapse>
    );
  };

  const listTile = (node: GraphNode) => {
    if (node.assetid.length === 0) {
      return node.uuid.string();
    }
    if (props.stripPrefix != null && node.assetid.startsWith(props.stripPrefix)) {
      let l = node.assetid.slice(props.stripPrefix.length);
      if (l.trimStart().startsWith("- ")) {
        return l.trimStart().slice(2);
      }
      return l;
    }
    return node.assetid;
  };
  const expandIcon = (node: GraphNode) => {
    if (!expandable(node)) {
      if (props.isNested) {
        return <div></div>;
      }
      return <div className={classes.expandIconHolder}></div>;
    }
    if (expanded) {
      return <ExpandMoreIcon onClick={(event) => handleExpandIconClick(event)} />;
    }
    return <ChevronRightIcon onClick={(event) => handleExpandIconClick(event)} />;
  };
  const deviceIcon = (graph: GraphNode, className: any, clicked: boolean) => {
    if (clicked) {
      return <CircularProgress size={20} />;
    }
    return <DeviceIcon type={graph} className={className} />;
  };

  if (activeGraph != null) {
    return (
      <React.Fragment>
        <ListItem
          style={props.style}
          button
          selected={isActiveNode(activeGraph)}
          key={activeGraph.uuid.string()}
          className={ancestor ? classes.listItemActive : undefined}
        >
          {expandIcon(activeGraph)}
          <ListItemIcon className={classes.listItemIcon} onClick={(event) => handleListItemClick(event)}>
            {deviceIcon(activeGraph, ancestor ? classes.typeIconActive : undefined, clicked && !isActiveNode(activeGraph))}
          </ListItemIcon>
          <ListItemText className={classes.listItemText} onClick={(event) => handleListItemClick(event)} primary={listTile(activeGraph)} />
        </ListItem>
        {expanded ? expand(activeGraph) : <React.Fragment />}
      </React.Fragment>
    );
  }
  return <Loading label={t("loading")} />;
};

export default GraphList;
