import { MenuItem, makeStyles, Theme, ListItemIcon, MenuList, Popper, Paper } from "@material-ui/core";
import { useContext, useState } from "react";
import { UserMetadata } from "@comsel/corona";
import { ArrowRight } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { CredentialsContext } from "../../context/CredentialContext";

const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: 1400,
  },
  icon: {
    justifyContent: "flex-end",
  },
}));

interface Props {
  open: boolean;
  openChange: (open: boolean) => void;
}

export const LanguageMenu = (props: Props) => {
  const [languageMenu, setLanguageMenu] = useState<HTMLElement | null>();
  const classes = useStyles();
  const [cred] = useContext(CredentialsContext);
  const [t] = useTranslation();

  const handleLocaleChange = (locale: string) => {
    if (locale !== localStorage["locale"] && (locale === "en" || locale === "sv" || locale === "fi")) {
      localStorage["locale"] = locale;
      let metadata = new UserMetadata("comsel.ServiceHub", "locale", locale);
      metadata.set(cred);
    }
  };

  const weSpeakSwedish = () => {
    window.location.reload(true);
    handleLocaleChange("sv");
    handleClose();
  };

  const weSpeakFinnish = () => {
    window.location.reload(true);
    handleLocaleChange("fi");
    handleClose();
  };
  const weSpeakEnglish = () => {
    window.location.reload(true);
    handleLocaleChange("en");
    handleClose();
  };
  const handleToggle = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setLanguageMenu(event.currentTarget);
    props.openChange(true);
  };
  const handleClose = () => {
    setLanguageMenu(null);
    props.openChange(false);
  };

  const MenuContent = () => {
    return (
      <Popper
        id="language-menu"
        className={classes.popper}
        anchorEl={languageMenu}
        open={props.open}
        placement="left-start"
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
        }}
      >
        <Paper elevation={8}>
          {localStorage["locale"] === "en" ? (
            <MenuList onMouseLeave={handleClose}>
              <MenuItem onClick={weSpeakSwedish}>Swedish</MenuItem>
              <MenuItem onClick={weSpeakFinnish}>Finnish</MenuItem>
            </MenuList>
          ) : localStorage["locale"] === "sv" ? (
            <MenuList onMouseLeave={handleClose}>
              <MenuItem onClick={weSpeakEnglish}>English</MenuItem>
              <MenuItem onClick={weSpeakFinnish}>Finnish</MenuItem>
            </MenuList>
          ) : (
            <MenuList onMouseLeave={handleClose}>
              <MenuItem onClick={weSpeakEnglish}>English</MenuItem>
              <MenuItem onClick={weSpeakSwedish}>Swedish</MenuItem>
            </MenuList>
          )}
        </Paper>
      </Popper>
    );
  };
  return (
    <React.Fragment>
      <MenuItem aria-haspopup="true" aria-controls={"language-menu"} onMouseEnter={handleToggle} selected={props.open}>
        {t("Layoutfolder.language")}
        <ListItemIcon className={classes.icon}>
          <ArrowRight />
        </ListItemIcon>
      </MenuItem>
      {MenuContent()}
    </React.Fragment>
  );
};

export default LanguageMenu;
