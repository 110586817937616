import { useState, useEffect } from "react";

export const useKeyPress = (targetKey: string) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    const downHandler = (ev: KeyboardEvent) => {
      const triggerable = (ev.target as any).tagName == null || (ev.target as any).tagName.toLowerCase() !== "input"; // dont trigger if we are in an input
      if (ev.key === targetKey && triggerable) {
        setKeyPressed(true);
      }
    };

    // If released key is our target key then set to false
    const upHandler = (ev: KeyboardEvent) => {
      const triggerable = (ev.target as any).tagName == null || (ev.target as any).tagName.toLowerCase() !== "input"; // dont trigger if we are in an input
      if (ev.key === targetKey && triggerable) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};
