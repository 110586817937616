import React, { useContext } from "react";
import { createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createTheme";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { DeviceIcon } from "@comsel/util-components";

import { UUID, Relationship, GraphNode } from "@comsel/corona";
import { makeStyles } from "@material-ui/core";
import { GraphContext } from "../../context/GraphContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadCrumbs: {
      color: theme.palette.primary.contrastText,
    },
    link: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.contrastText,
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  })
);

export const BreadCrumbs = () => {
  const classes = useStyles();
  const [graph] = useContext(GraphContext)
  function graphToPath(includeNeg=false): Array<any> {
    // TODO MOVE TO Corona lib
    function recursiveParents(path: Array<GraphNode>, p: Relationship): Array<any> {
      if (p.node.uuid.string() !== "00000000-0000-0000-0000-000000000000" && p.weight>=0) {
        path.push(p.node);
      }
      if (p.node.parents.length === 0) {
        return path;
      }
      return recursiveParents(path, p.node.parents[0]);
    }

    let path = [];
    if (graph) {
      path.push(graph);
      if (graph.parents.length === 1) {
        for (const parent of graph.parents) {
          path = recursiveParents(path, parent);
        }
      }
      if (graph.parents.length > 1) {
        for (const parent of graph.parents) {
          if (!(parent.type === 2 || parent.type === 6) && parent.weight>=0) {
            path = recursiveParents(path, parent);
          }
        }
      }
      // deduplicate path
      const dedupe = (a: Array<GraphNode>): Array<GraphNode> => {
        let seen: { [key: string]: number } = {};
        let out = [];
        let len = a.length;
        let j = 0;
        for (let i = 0; i < len; i++) {
          let item = a[i];
          if (seen[item.assetid] !== 1) {
            seen[item.assetid] = 1;
            out[j++] = item;
          }
        }
        return out;
      };
      return dedupe(path).reverse();
    }
    return [];
  }
  const title = (node: GraphNode, prevNode: GraphNode | undefined) => {
    if (node.assetid.length === 0) {
      return node.uuid.string();
    }
    if (prevNode != null && node.assetid.startsWith(prevNode.assetid)) {
      let l = node.assetid.slice(prevNode.assetid.length);
      if (l.trimStart().startsWith("- ")) {
        return l.trimStart().slice(2);
      }
      return l;
    }
    return node.assetid;
  };
  let ret = <div></div>;
  const link = (uuid: string): string => {
    if (uuid === "00000000-0000-0000-0000-000000000000") {
      return "/assets";
    }
    return "/asset/" + uuid + "/data";
  };
  if (graph != null && !graph.uuid.equals(new UUID("00000000-0000-0000-0000-000000000000"))) {
    const path = graphToPath();
    ret = (
      <Breadcrumbs className={classes.breadCrumbs} separator={<NavigateNextIcon fontSize="small" />}>
        {path.map((node, i) => (
          <Link key={node.uuid.string()} href={link(node.uuid.string())} className={classes.link}>
            <DeviceIcon type={node} className={classes.icon} /> {title(node, path[i - 1])}
          </Link>
        ))}
      </Breadcrumbs>
    );
  }
  return ret;
};

export default BreadCrumbs;
