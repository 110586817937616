import React, { useContext, useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Alarm } from "@comsel/corona";
import { getAlarms } from "@comsel/corona/dist/alarm";
import { AlertSeverity } from "@comsel/corona/dist/alert";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createTheme";
import { CredentialsContext } from "../../context/CredentialContext";
import TextField from "@material-ui/core/TextField";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
const useStyles = makeStyles((theme: Theme) => ({
  alarmContainer: {
    position: "absolute",
    top: 50,
    right: 50,
    maxWidth: "25%",
    overflowY: "scroll",
    maxHeight: "80vh",
    [theme.breakpoints.down("md")]: {
      maxWidth: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      right: 0,
    },
  },
}));

export const AlarmButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const [cred] = useContext(CredentialsContext);
  const [showAlerts, setShowAlerts] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(new Date(0));
  //const [lastSeen, setLastSeen] = useState(new Date(0));
  const [alarms, setAlarms] = useState<Array<Alarm>>([]);
  const [severity, setSeverity] = useState(0);
  const [filter, setFilter] = useState('');
  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };
  const handleSeverity =  (event: React.ChangeEvent<{ value: unknown }>) => {
    setSeverity(event.target.value as number);
  }


  useEffect(() => {
    let d = new Date();
    d.setDate(d.getDate() - 7);
    if (alarms.length === 0 && cred.valid() && Date.now() - lastUpdate.getTime() > 86400000) {
      getAlarms(d, cred).then((a) => {
        setAlarms(a);
        setLastUpdate(new Date());
      });
    }
  }, [alarms, lastUpdate, cred]);

  interface AlarmComponentProps {
    alarm: Alarm;
    onNavigate?: () => void;
  }

  const AlarmComponent = (props: AlarmComponentProps) => {
    const severityToSeverity = (severity: AlertSeverity): "info" | "warning" | "error" | "success" => {
      if (severity === AlertSeverity.Critical) {
        return "error";
      }
      if (severity === AlertSeverity.Warning) {
        return "warning";
      }
      if (severity === AlertSeverity.Debug) {
        return "success";
      }
      return "info";
    };
    const title = props.alarm.assetID != null && props.alarm.assetID.length > 0 ? props.alarm.assetID : props.alarm.uuid.string();
    const time =
      props.alarm.start.getHours() > new Date().getHours() || props.alarm.start.getDate() !== new Date().getDate()
        ? props.alarm.start.toLocaleDateString()
        : props.alarm.start.toLocaleTimeString();
    return (
      <Alert
        severity={severityToSeverity(props.alarm.severity)}
        variant="outlined"
        style={{ margin: "10px", cursor: "pointer" }}
        onClick={() => {
          if (props.onNavigate != null) {
            props.onNavigate();
          }
          history.push("/asset/" + props.alarm.uuid.string() + "/status");
        }}
        action={<div style={{ alignSelf: "baseline" }}>{time}</div>}
      >
        <AlertTitle>{title}</AlertTitle>
        {props.alarm.message}
      </Alert>
    );
  };

  const filterAlarms = (v: Alarm, i: number): boolean => {
    if (filter.length===0) { return (severity===0 || v.severity===severity); }
    let candidate = (v.uuid.contains(filter) || v.assetID.toLowerCase().includes(filter.toLowerCase()) || v.message.toLowerCase().includes(filter.toLowerCase()));
    if (severity>0) {
      return candidate && v.severity===severity;
    }
    return candidate
  }

  return (
    <>
      <IconButton color="inherit" onClick={() => setShowAlerts(!showAlerts)}>
        <Badge badgeContent={alarms.length} max={9999} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {showAlerts ? (
        <Paper elevation={2} className={classes.alarmContainer}>
          <div style={{ display: "flex", flex: 1, margin: "10px" }}>
            <TextField style={{ display: "flex", flex: 1 }} id="alarm-filter" label="Filter" value={filter} onChange={handleFilter} />
            <FormControl style={{marginLeft: "10px"}}>
              <InputLabel id="severity-label">Severity</InputLabel>
              <Select
                labelId="severity-label"
                id="severity"
                value={severity}
                onChange={handleSeverity}
              >
                <MenuItem value={0}>Not filtered</MenuItem>
                <MenuItem value={1}>Critical</MenuItem>
                <MenuItem value={2}>Warning</MenuItem>
                <MenuItem value={3}>Info</MenuItem>
                <MenuItem value={4}>Debug</MenuItem>
              </Select>
            </FormControl>
          </div>
          {alarms.filter(filterAlarms).map((a) => (
            <AlarmComponent key={a.id} alarm={a} onNavigate={() => setShowAlerts(false)} />
          ))}
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
};

export default AlarmButton;
