import React, { useState, useEffect } from 'react';
import { FilterContext, FilterContextInterface } from './context/FilterContext';
import { GraphContext, GraphContextDefault, GraphContextInterface } from './context/GraphContext';
import { UserContext, UserContextInterface, UserContextDefault } from './context/UserContext';


interface Props {
  children: JSX.Element
}

export const ContextControl = (props: Props) => {
  const [graph, setGraph] = useState<GraphContextInterface>(GraphContextDefault);
  const [user, setUser] = useState<UserContextInterface>(UserContextDefault);
  const [filter, setFilter] = useState<FilterContextInterface>("")

  useEffect(() => {
    let search = window.location.search;
    if (search.length>0) {
      let qParts = search.split("q=");
      if (qParts.length>1) {
        setFilter(decodeURIComponent(qParts[1].split("&")[0]));
      }
    }
  }, []);

  return <>
    <UserContext.Provider value={[user, setUser]}>
    <GraphContext.Provider value={[graph, setGraph]}>
    <FilterContext.Provider value={[filter, setFilter]}>
      {props.children}
    </FilterContext.Provider>
    </GraphContext.Provider>
    </UserContext.Provider>
  </>
}